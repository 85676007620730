<template>
  <div class="card">
    <h2 class="card-title my-3">Sign Up</h2>

    <span style="color: #ff0087">
      This device requires a
      <strong>Speedify for Routers</strong>
      license.
    </span>

    <ul>
      <li>Activate your ”Powered by Speedify” router</li>
      <li>Includes 3TB of monthly data</li>
    </ul>

    <a
      class="m-2 w-100 d-flex justify-content-center btn btn-speedify-blue"
      :href="`${cartUrl}/checkout?plan=speedify-pro-router-3000gb-yearly`"
      target="_blank"
    >
      Subscribe for
      <Skeleton v-if="!prices" />
      <span v-else class="price">${{ prices['speedify-pro-router-3000gb-yearly'] }}</span>
      / year
    </a>
    <a
      class="m-2 mb-4 w-100 d-flex justify-content-center btn btn-speedify-outline"
      :href="`${cartUrl}/checkout?plan=speedify-pro-router-3000gb-monthly`"
      target="_blank"
    >
      Subscribe for
      <Skeleton v-if="!prices" />
      <span v-else class="price">${{ prices['speedify-pro-router-3000gb-monthly'] }}</span>
      / month
    </a>

    <small style="color: gray">Prices displayed in USD</small>

    <div style="border: 1px solid #dfdfdf"></div>

    <RedeemCoupon />

    <div v-if="showLoginLink">
      <b>Already have a router plan?</b>
      <router-link :to="{ name: 'LegacyLogin' }">Sign in with a different account</router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

import Skeleton from 'primevue/skeleton';

import RedeemCoupon from '@/components/RedeemCoupon.vue';
import { getPricingInfo, PricingInfo } from '@/services/backend';

defineProps<{ showLoginLink: boolean }>();

const cartUrl: string = import.meta.env.VITE_CART_URL;
const prices = ref<PricingInfo>();

onMounted(async () => {
  prices.value = await getPricingInfo();
});
</script>

<style scoped media="screen">
li::marker {
  color: gray;
}

li {
  font-size: 14px;
  color: gray;
  text-align: left;
}

span {
  text-align: center;
}

.price {
  padding: 0 0.5em;
}
</style>
