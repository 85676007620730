<template>
  <div class="card px-sm-3 px-3 py-4 text-center text-md-center width-md-400">
    <h2 class="card-title mb-3">Welcome Back</h2>

    <span style="color: #ff0087">
      In order to continue, you’ll need to add an additional
      <strong>Speedify for Routers</strong>
      license to your account.
    </span>

    <small v-if="hasDiffRouterSubs" style="color: gray">Select your desired license type and quantity</small>
    <small v-else style="color: gray; margin: 0 0.5rem">Increase the quantity on your current subscription or add a new one</small>

    <div class="d-flex justify-content-between w-100 column-gap-2" style="margin: 0 0.5rem">
      <Dropdown
        v-model="plancode"
        :options="options"
        :loading="loading"
        optionLabel="name"
        optionValue="code"
        style="flex-grow: 1; text-align: left"
        :pt:trigger:style="hasDiffRouterSubs ? '' : 'display: none'"
        pt:input:style="border-radius: var(--bs-border-radius); padding: 0.5rem 1rem !important"
        pt:list:style="padding-left: 1rem; margin-bottom: 0"
        pt:wrapper:style="padding: 0"
        pt:item:style="padding: 0.5rem 0"
      />
      <!-- bug of inconsistent styling syntax  https://github.com/primefaces/primevue/issues/5528 -->
      <InputNumber
        v-model="state.quantity"
        inputId="integeronly"
        :min="1"
        :max="99"
        :inputStyle="{borderRadius: '.4rem', padding: '0.5rem 1rem'}"
      />
    </div>

    <a
      class="m-2 w-100 d-flex justify-content-center btn btn-speedify-blue"
      :href="`${cartUrl}/checkout?plan=${plancode}&quantity=${state.quantity || 1}`"
      target="_blank"
    >
      Add {{ state.quantity }} License{{ state.quantity > 1 ? 's' : '' }} for
      <Skeleton v-if="!prices" />
      <span v-else class="price">${{ state.quantity * prices[plancode!] }}</span>
      / {{ term }}
    </a>
    <span v-if="!hasDiffRouterSubs">OR</span>
    <a
      v-if="!hasDiffRouterSubs"
      class="m-2 mb-4 w-100 d-flex justify-content-center btn btn-speedify-outline"
      :href="`${cartUrl}/checkout?plan=${getOtherCode(plancode)}`"
      target="_blank"
    >
      Subscribe for
      <Skeleton v-if="!prices" />
      <span v-else-if="plancode" class="price">${{ prices[getOtherCode(plancode!)] }}</span>
      / {{ flip(term) }}
    </a>
    <small style="color: gray">Prices displayed in USD</small>

    <Divider />

    <RedeemCoupon />
  </div>
</template>

<script setup lang="ts" charset="utf-8">
import { onMounted, ref, reactive, computed } from 'vue';

import Skeleton from 'primevue/skeleton';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Divider from 'primevue/divider';

import RedeemCoupon from '@/components/RedeemCoupon.vue';
import { getPricingInfo, PricingInfo } from '@/services/backend';
import { useUserStore } from '@/store/user';

type PlanCode = keyof PricingInfo;
const userStore = useUserStore();

const nameof = (code: PlanCode) => {
  const yearly = 'Speedify for Routers Yearly';
  const monthly = 'Speedify for Routers Monthly';
  return code.match(/yearly/) ? yearly : monthly;
};

const cartUrl: string = import.meta.env.VITE_CART_URL;
const subs = userStore.routerSubscriptions;
const init = (subs[0]?.planCode ?? 'speedify-pro-router-3000gb-yearly') as PlanCode ;
type Plan = { code: PlanCode; name: string; price: number };
const plancode = ref<PlanCode>(init);
const state = reactive({ quantity: 1 });
const prices = ref<PricingInfo>();
const options = ref<Plan[]>([]);
const loading = ref(true);
const term = computed<'year' | 'month'>(() => (plancode.value?.endsWith('yearly') ? 'year' : 'month'));
const flip = (term: 'year' | 'month') => (term === 'year' ? 'month' : 'year');
const hasDiffRouterSubs = new Set(subs.map(sub => sub.planCode)).size > 1;

const getOtherCode = (code: PlanCode) => {
  const arr = code.split('-');
  arr.push(arr.pop() === 'yearly' ? 'monthly' : 'yearly');
  return arr.join('-') as PlanCode;
};

onMounted(async () => {
  const info = await getPricingInfo();
  prices.value = info;
  options.value = Object.entries(info)
    .filter(([code]) => code.match(/router/))
    .map(([code, price]) => ({
      code: code as PlanCode,
      name: nameof(code as any),
      price: price,
    }));
  loading.value = false;
});
</script>

<style>
span > input {
  width: 4rem;
  text-align: right;
}
</style>
