import { defineStore } from 'pinia';
import { Mutex } from 'async-mutex';

import { getUser, getLinkedDevices  } from '@/services/backend';
import { LinkedDevice, ActiveDevice, Subscription, User, Role, PrivateServer } from '../model';

export interface Store extends User {
  servers: PrivateServer[],
  subscriptions: Subscription[],
  freeLicenseDataGB: number,
  usedDataGB: number,
  success: boolean,
}

interface ISubscriptionQtyUsageDetails {
  used: number;
  allowed: number;
}

interface ISubscriptionUsageDetails {
  productName: string;
  referenceId: string;
  qty: ISubscriptionQtyUsageDetails;
}

const makeDefaultData = () => ({
  mutex: new Mutex(),
  linkedDeviceRefreshMutex: new Mutex(),
  success: false,

  userid: 0,
  email: '',
  title: '',
  licenses: [],
  servers: [] as PrivateServer[],
  activeDevices: [] as ActiveDevice[],
  linkedDevices: [] as LinkedDevice[],
  subscriptions: [] as Subscription[],
  freeLicenseDataGB: 0,
  usedDataGB: 0,
});

export const useUserStore = defineStore('user', {
  state: (): Store & { mutex: Mutex; linkedDeviceRefreshMutex: Mutex } => makeDefaultData(),
  getters: {
    routers(state): LinkedDevice[] {
      return state.linkedDevices.filter(d => d.role === 'router');
    },
    routerSubscriptions(state): Subscription[] {
      return state.subscriptions.filter(s => s.productName.match(/router/i));
    },
    routerQty(): number {
      return this.routerSubscriptions.reduce((a, v) => a + v.quantity, 0);
    },
    routerSubscriptionQtyUsageMap(state): Record<string, number> {
      return state.linkedDevices.reduce((a, v) => {
        if (!v.routerReferenceId) {
          return a;
        }
        if (!a[v.routerReferenceId]) {
          a[v.routerReferenceId] = 1;
        } else {
          a[v.routerReferenceId]++;
        }
        return a;
      }, {} as Record<string, number>);
    },
    routerSubscriptionUsageDetails(): ISubscriptionUsageDetails[] {
      const details = this.routerSubscriptions.map((s) => {
        const allowedQty = s.quantity;
        const usedQty = this.routerSubscriptionQtyUsageMap[s.referenceId] || 0;

        return {
          productName: s.productName,
          referenceId: s.referenceId,
          qty: {
            used: usedQty,
            allowed: allowedQty,
          },
        };
      });

      return details;
    },
    hasTeamSubscription(state) {
      return state.subscriptions.filter(s => s.type === 'teams' && s.active);
    },
    teams(state) {
      return state.subscriptions.map(s => s.type === 'teams' && s.team).filter(s => s);
    },
    teamRouterAssociations(state) {
      const teamRouterMapping = state.subscriptions.reduce((a, v) => {
        let routerSubs: string[] = [];
        if (v.type === 'teams') {
          routerSubs = v.team!.associatedRouterSubContexts?.map(c => c.routerReferenceId) || [];
        }
        a[v.referenceId] = routerSubs;
        return a;
      }, {} as Record<string, string[]>);
      return teamRouterMapping;
    },
    dedicatedServerQuantity(state) {
      return state.subscriptions
        .filter(e => e.type === 'dedicated_server')
        .reduce((a, v) => a+v.quantity, 0);
    },
  },
  actions: {
    // state.$reset is not available for <script setup>
    // https://stackoverflow.com/questions/71690883/pinia-reset-alternative-when-using-setup-syntax
    reset() {
      this.$state = makeDefaultData();
    },
    async refresh(force:boolean=false) {
      await this.mutex.waitForUnlock();

      await this.mutex.runExclusive(async () => {
        if (force) {
          this.success = false;
        }

        let data; // TODO: refactor me
        if (force || ((this.userid === 0 && this.email === '') || !this.success)) {
          data = await getUser();
          if (!data) return this.success = false;
        }

        if (this.userid === 0 && this.email === '') {
          const { userid, email, activeDevices, servers } = data;
          this.userid = userid;
          this.email = email.toLowerCase();
          this.activeDevices = activeDevices;

          const linkedDevices = await getLinkedDevices() as LinkedDevice[];
          this.linkedDevices = linkedDevices;
          // TODO:
          // This includes both private individual servers as well as teams servers
          // do we need to filter at all? discussion is needed
          this.servers = servers as PrivateServer[];
        }

        if (!this.success) {
          this.subscriptions = data.subscriptions;
          this.freeLicenseDataGB = data.freeLicenseDataGB;
          this.usedDataGB = data.usedDataGB;
          this.success = true;
        }
      });
    },
    async refreshLinkedDevices() {
      if (this.linkedDeviceRefreshMutex.isLocked()) {
        return;
      }
      await this.linkedDeviceRefreshMutex.runExclusive(async () => {
        const linkedDevices = await getLinkedDevices() as LinkedDevice[];
        this.linkedDevices = linkedDevices;
      });
    },
    removeActiveDevice(uuid: string) {
      this.activeDevices = this.activeDevices.filter(d => d.deviceUuid !== uuid);
    },
    appendAPIKeyToSubscription(referenceId: string, apiKey: string, description: string) {
      this.subscriptions.map((s) => {
        if (s.referenceId === referenceId) {
          s.team!.apiKeys.push({
            apiKey,
            description,
            dateGenerated: new Date(),
            keyId: 0,
            userid: 0,
            teamId: 0,
            role: 'user',
            expirationDate: new Date(),
            invalidated: 0,
          });
        }
      });
    },
    revokeAPIKeyFromSubscription(referenceId: string, apiKey: string) {
      this.subscriptions.map((s) => {
        if (s.referenceId === referenceId) {
          // We want to delete the api key which has been revoked
          // eslint-disable-next-line no-param-reassign
          s.team!.apiKeys = s.team!.apiKeys.filter((k) => k.apiKey !== apiKey);
        }
        return s;
      });
    },
    addTeamMember(referenceId: string, email: string, role: Role) {
      this.subscriptions.map((s) => {
        if (s.referenceId === referenceId) {
          s.team!.teamMembers.push({
            dateGenerated: new Date(),
            email,
            role,
            usageBytesThisPeriod: 0,
            usedGB: 0,
            firstName: null,
            lastName: null,
            teamId: 0,
            userid: 0,
          });
        }
        return s;
      });
    },
    removeTeamMember(referenceId: string, email: string) {
      this.subscriptions.map((s) => {
        if (s.referenceId === referenceId) {
          // We want to delete the user object which was removed from the team
          // eslint-disable-next-line no-param-reassign
          s.team!.teamMembers = s.team!.teamMembers.filter((m) => m.email !== email);
        }
        return s;
      });
    },
    updateSeatQuantity(referenceId: string, quantity: number) {
      this.subscriptions.map((s) => {
        if (s.referenceId === referenceId) {
          // eslint-disable-next-line no-param-reassign
          s.quantity = quantity;
        }
        return s;
      });
    },
    updateLinkedDeviceAssociation(uuid: string, referenceId: string | null) {
      this.linkedDevices = this.linkedDevices.map((d) => {
        if (d.deviceUUID === uuid) {
          d.routerReferenceId = referenceId;
        }

        return d;
      });
    },
  },
});
