<template>
  <div v-if="user.routers.length > user.routerQty" class="container mt-5">
    <div class="alert alert-danger" role="alert">
      <h4 class="alert-heading">Trying to set up a new router?</h4>
      <p class="mb-0">
        Please purchase a router subscription
        <a :href='cartUrl + "/checkout/?plan=speedify-pro-router-3000gb-yearly"' target="_blank">from our store</a>

        <br />

        We noticed that you may be trying to set up a new router with Speedify. If this is the case,
        you will need to purchase a license specifically for your router.

        <br />
        <br />

        Please contact support@speedify.com if you would like some assistance.
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '@/store/user';
const user = useUserStore();

const cartUrl = import.meta.env.VITE_CART_URL;
</script>
