<template>
  <nav class="navbar navbar-expand-lg navbar-light py-3">
    <div class="container justify-content-center">
      <router-link class="navbar-brand logo-container pt-5" to="/" aria-describedby="speedify-logo-desc">
        <div id="speedify-logo-desc" style="display: none">Speedify home page</div>
        <SpeedifyLogo />
      </router-link>
    </div>
  </nav>

  <div class="container-lg px-2 px-md-5">
    <div class="row mx-sm-3 mt-5 mx-0 mb-3 justify-content-center align-items-stretch">
      <div class="col-md-7 col-lg-6 col-xl-5 px-1 px-sm-2 mb-2 d-flex justify-content-center flex-wrap">
        <component :is="welcome" />

        <div class="justify-content-center">
          <h6 class="text-center mt-4">Need Help?</h6>
          <small>
            Contact us at
            <a href="mailto:support@speedify.com" class="speedify-blue">support@speedify.com</a>
          </small>
        </div>
      </div>
    </div>
  </div>
  <PageFooter />
</template>

<script setup lang="ts">
import { onBeforeMount, shallowRef } from 'vue';
import type { Component } from 'vue';

import SpeedifyLogo from '@/components/SpeedifyLogo.vue';
import PageFooter from '@/components/PageFooter.vue';
import ClientComponent from '@/components/welcome/ClientComponent.vue';
import RouterComponent from '@/components/welcome/RouterComponent.vue';

import { useUserStore } from '@/store/user';
import { getToken } from '@/services/backend';

const userStore = useUserStore();
const welcome = shallowRef<Component>();

onBeforeMount(async () => {
  await userStore.refresh(); // No side effect from <AuthGuard /> unless visited directly to /welcome
  const numRouterSubs = userStore.routerQty;
  const payload = await getToken();
  if (payload?.role === 'router') {
    if (numRouterSubs === 0) {
      return (welcome.value = RouterComponent);
    }

    if (numRouterSubs < userStore.routers.length) {
      return (welcome.value = RouterComponent);
    }
  }

  if (!userStore.subscriptions.length) {
    welcome.value = ClientComponent;
  }
});
</script>
