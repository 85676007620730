<template>
  <div class="container mt-5">
    <nav aria-label="breadcrumb my-3">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><router-link :to="{ name: 'AccountDashboard' }">Dashboard</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">Your Speedify Subscription</li>
      </ol>
    </nav>
    <h1 class="mb-3">Your Speedify Subscription</h1>

    <div class="row my-2">
      <div class="col-sm">
        <div class="card mb-3">
          <div class="card-body pb-4">
            <div class="card-title">
              <small>Owner</small>
            </div>
            <h5>{{ subscription!.ownerEmail }}</h5>
          </div>
        </div>
      </div>
      <div class="col-sm mb-3">
        <div id="email" class="card">
          <div class="card-body pb-4">
            <div class="card-title">
              <small>Renews on</small>
            </div>
            <h5>{{ new Date(subscription!.nextPeriodDate!).toDateString() }}</h5>
          </div>
        </div>
      </div>
    </div>
  <div class="row mb-4">
    <div class="col-sm">
      <div class="card h-100">
        <div class="card-body pb-4">
          <div class="card-title">
            <small>Seats</small>
          </div>
          <h5>1</h5>
          <!--
            TODO:
            we need a modal to explain why the user was redirected but idk what it should
            look like, what it should say, etc. waiting on scott for advice
          -->
          <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
          <router-link
            v-if="upgradeSupportedStores.includes(subscription.storeType)"
            type="button"
            class="text-decoration-none stm-btn-modify-seats"
            :to="{ name: 'UpgradePortal', query: { referenceId: subscription.referenceId, tier: 'team' } }"
          >
            <small>Want mmore seats?</small>
          </router-link>
        </div>
      </div>
    </div>
    <div class="col-sm">
      <div class="card h-100">
        <div class="card-body pb-4">
          <div class="card-title">
            <small>Assigned Seats</small>
          </div>
          <h5>1</h5>
        </div>
      </div>
    </div>
  </div>

  <FakeIndividualTeamMemberTable :subscription="subscription" />

  <DedicatedServers :subscription="subscription" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useUserStore } from '@/store/user';
import DedicatedServers from '@/components/DedicatedServers.vue';
import { Subscription } from '@/model';
import FakeIndividualTeamMemberTable from '@/components/FakeIndividualTeamMemberTable.vue';

const route = useRoute();
const { referenceId } = route.query;
const userStore = useUserStore();

const subscription = computed<Subscription>(
  () => userStore.subscriptions.find(s => s.referenceId === referenceId)!,
);

const upgradeSupportedStores = ['REC'];
</script>
