<template>
  <!-- TODO: purchase modal -->
  <div class="container-fluid pb-2 d-flex justify-content-center flex-wrap p-3 mb-4  border rounded-3">
    <div class="w-100 text-center">
      <img src="/images/DEDICATED-SERVER.png" alt="">
      </div>
    <h5 class="w-100 text-center mt-3">No Dedicated Servers</h5>
    <p class="col-xl-4 col-lg-6 col-md-8 text-center text-secondary">
      Dedicated servers provide speed, reliability, port forwarding, and static IP addresses for your entire team.
    </p>
    <div class="w-100 d-flex justify-content-center">
      <button
        class="btn btn-speedify-blue col-lg-4 col-md-6 col-sm-12 stm-btn-add-dedi-new-purchase-flow"
        type="button"
        @click="$emit('showModal', 'purchaseDediModal')"
      >
        <i class="fas fa-plus"></i>&nbsp; Add a Dedicated Server
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>
