<template>
  <RouterSubscriptionMissingBanner />
  <IndividualSubscriptionManagementPortal v-if="subType === 'individual'" />
  <TeamManagementPortal v-if="subType === 'team'" :referenceId="referenceId" />
  <RouterManagementPortal v-if="subType === 'router'" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import RouterSubscriptionMissingBanner from '@/components/RouterSubscriptionMissingBanner.vue';
import { useUserStore } from '@/store/user';
import TeamManagementPortal from '@/components/TeamManagementPortal.vue';
import RouterManagementPortal from '@/components/RouterManagementPortal.vue';
import { Subscription } from '@/model';
import IndividualSubscriptionManagementPortal from '@/components/IndividualSubscriptionManagementPortal.vue';

const route = useRoute();
const { referenceId } = route.query as { [key: string]: string };
const { subscriptions } = useUserStore();

const subscription = computed<Subscription>(
  () => subscriptions.find(s => s.referenceId === referenceId)!,
);

const subType = computed(() => {
  if (subscription.value.type.match(/team/) || subscription.value.type.match(/fam/)) {
    return 'team';
  }
  if (subscription.value.type.match(/router/i)) {
    return 'router';
  }
  return 'individual';
});
</script>
